/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:44861973-290c-4342-8663-0dfdc5be807f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ZjCpIbXl9",
    "aws_user_pools_web_client_id": "1f02dha4gs24qjsk4s4ntdep39",
    "oauth": {
        "domain": "wateenhelfa91df84-fa91df84-test.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.wateenhel.nl/,https://www.wateenhel.nl/",
        "redirectSignOut": "http://localhost:3000/,https://dev.wateenhel.nl/,https://www.wateenhel.nl/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
